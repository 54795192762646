<ui-card class="pt-6 w-full relative pb-0">
  <div class="flex px-6">
    <div class="grow">
      <h1 class="text-dark text-2xl font-bold">Create video from a template</h1>
      <p class="text-[#5F5F5F] text-sm mt-1 mb-3">Start a new video from the most recent templates</p>
    </div>
    @if (showLinkArrow()) {
    <a
      routerLink="/video/templates/"
      class="text-neutral-400 transition-colors right-4 top-5 items-center justify-center flex w-10 h-10 hover:bg-lighter rounded-full text-2xl default-ring focus:bg-lighter focus:transition-none focus-visible:bg-lighter">
      <ng-icon name="heroChevronRight" class="inline-block" aria-hidden="true"></ng-icon>
    </a>
    }
  </div>

  <div #container #tourTarget class="w-full h-full relative bg-white py-3 px-6 pb-6 rounded-b-lg">
    <div
      *ngIf="showNextClickButton"
      class="absolute h-[338px] top-0 flex items-center justify-center w-10 -right-4 z-10">
      <button
        class="w-full aspect-square bg-darker transition-colors hover:bg-dark rounded-full default-ring text-white flex items-center justify-center"
        (click)="onNextClick()">
        <ng-icon name="heroChevronRight" class="text-xl" />
      </button>
    </div>
    <div
      *ngIf="showPreviousClickButton"
      class="absolute h-[338px] top-0 flex items-center justify-center w-10 -left-4 z-10">
      <button
        class="w-full aspect-square bg-darker transition-colors hover:bg-dark rounded-full default-ring text-white flex items-center justify-center"
        (click)="onPreviousClick()">
        <ng-icon name="heroChevronLeft" class="text-xl" />
      </button>
    </div>
    <ng-container *ngIf="isError; else noError">
      <div class="grow items-center justify-center flex flex-col mt-1.5 h-[366px]">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="h-[5.25rem] aspect-square text-neutral-200 mb-0.5">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M5.31171 10.7615C8.23007 5.58716 9.68925 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.31034 17.856 2.52291 15.7061 4.94805 11.4063L5.31171 10.7615Z"
              stroke="currentColor"
              stroke-width="1.5"></path>
            <path d="M12 8V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
            <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
          </g>
        </svg>
        <div class="text-neutral-700 font-medium mb-1">An error occurred</div>
      </div>
    </ng-container>
    <ng-template #noError>
      <ng-container *ngIf="isLoading; else noLoading">
        <div class="h-[366px]">
          <div class="overflow-x-hidden flex gap-4 px-2">
            <!-- <div>
              <div class="p-1 block aspect-[1080/1920] h-[338px] mb-1">
                <div class="rounded-lg bg-neutral-100 h-full animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div> -->
            <div>
              <div class="h-[338px] p-1 mb-1">
                <div class="rounded-xl bg-neutral-100 h-full aspect-mobile animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div>
            <div>
              <div class="h-[338px] p-1 mb-1">
                <div class="rounded-xl bg-neutral-100 h-full aspect-mobile animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div>
            <div>
              <div class="h-[338px] p-1 mb-1">
                <div class="rounded-xl bg-neutral-100 h-full aspect-square animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div>
            <div>
              <div class="h-[338px] p-1 mb-1">
                <div class="rounded-xl bg-neutral-100 h-full aspect-mobile animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div>
            <div>
              <div class="h-[338px] p-1 mb-1">
                <div class="rounded-xl bg-neutral-100 h-full aspect-mobile animate-pulse"></div>
              </div>
              <div class="p-1 text-xs truncate font-medium">
                <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noLoading>
        <div
          *ngIf="templates"
          [ngClass]="{ 'pointer-events-none': isTourActive() }"
          #templateContainer
          class="overflow-x-auto flex gap-4 px-2"
          (scroll)="onScroll()">
          <div *ngFor="let template of templates.results">
            <ui-template-card [template]="template" class="block w-fit h-[338px] mb-1" />
            <div class="p-1 text-xs truncate font-medium">
              {{ template.title }}
            </div>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ui-card>
