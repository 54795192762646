<ui-card
  *ngIf="showDialog"
  [@enterFromBottom]
  (@enterFromBottom.done)="attemptCloseDialog()"
  class="p-6 max-w-lg w-screen max-h-screen relative pt-12 transition-all delay-[250ms]"
  [ngClass]="{ 'h-[49rem]': currentStep === 0, 'h-[24rem]': currentStep === 1 }">
  <div class="top-2 right-2 absolute">
    <button
      class="text-xl flex items-center gap-2 p-1 rounded-full hover:bg-neutral-100 transition-colors"
      (click)="closeOverlay()">
      <ng-icon name="heroXMark" class="w-4 h-4" />
    </button>
  </div>
  <div class="flex flex-col h-full w-full relative">
    <ui-stepper class="w-full shrink-0" [steps]="2" [currentStep]="currentStep" />
    <div class="mt-8 mb-4 px-2 grow block overflow-y-auto">
      <h1 class="text-2xl font-extrabold text-darker mb-2">Create with AI</h1>
      @switch (currentStep) { @case(0) {
      <div [@delayedAbsFadeInOutFromRight]>
        <p class="text-sm text-darker mb-6">
          We'll do the heavy lifting. Just provide a few details and our AI will take care of the rest.
        </p>
        <div class="mb-2 text-sm text-dark font-medium">Video</div>
        <div class="mb-4 text-xs bg-sky-100 text-blue-800 p-4 px-6 rounded-lg flex gap-4 items-center w-full">
          <div class="h-8 w-fit text-blue-600">
            <svg class="h-full" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5"></circle>
                <path d="M12 17V11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
                <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 11 9)" fill="currentColor"></circle>
              </g>
            </svg>
          </div>
          <div>
            <div class="mb-2">
              We only support videos that are in English. Please do not upload videos that are not in English, as our AI
              will not be able to process them.
            </div>
            <div>
              Video length is currently restricted to 30 minutes. We're working on increasing this limit, so stick with
              us!
            </div>
          </div>
        </div>
        <div class="flex gap-4 items-center mb-4">
          <div
            class="aspect-video shrink-0 bg-neutral-100 rounded overflow-hidden h-20 flex items-center justify-center">
            @if (isLoadingData()) {
            <svg
              class="animate-spin h-4 w-4 text-dark opacity-50"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            } @if (videoFileAsUrl(); as videoFile) {
            <!-- we put the video here, but do not play it. -->
            <video #videoPlayer [src]="videoFile" class="h-full w-full" playsinline muted preload="metadata"></video>
            } @else if (videoThumbnail()) {
            <img [src]="videoThumbnail()" alt="Video thumbnail" class="height-full" />
            }
          </div>
          <div class="truncate">
            @if (isLoadingData()) {
            <div class="h-4 bg-light animate-pulse w-48 mb-2 rounded"></div>
            <div class="h-3 bg-lighter animate-pulse w-16 rounded"></div>
            } @else { @if (videoDataObservable() | async; as videoData) {
            <div class="font-bold text-sm text-dark grow truncate mb-1">
              {{ videoData.title }}
            </div>
            <div class="text-xs text-neutral-500 font-light">
              {{ videoData.length }}
            </div>
            } @if (videoLength() | async; as _videoLength) { @if (_videoLength < SHORT_VIDEO_THRESHOLD) {
            <div class="text-xs font-light mt-1 text-mid flex items-center gap-1">
              <ng-icon name="heroExclamationCircle" class="text-sm h-4 w-4" aria-hidden="true" />
              <p class="text-wrap">This video may be too short for optimal results.</p>
            </div>
            } @else if (_videoLength >= LONG_VIDEO_THRESHOLD) {
            <div class="text-xs font-light mt-1 text-red-800 flex items-center gap-1">
              <ng-icon name="heroExclamationCircle" class="text-sm h-4 w-4 shrink-0" aria-hidden="true" />
              <div class="text-wrap">This video is too long to process.</div>
            </div>
            } } }
          </div>
        </div>
        <div class="h-px bg-neutral-100 w-full mb-4"></div>
        <div class="mb-4 text-sm text-dark font-medium">Captions</div>
        <div class="relative">
          <div *ngIf="showNextClickButton()" class="relative" [@fadeInOut]>
            <div class="absolute h-48 top-2 flex items-center justify-center w-10 right-0 z-10">
              <button
                class="w-full aspect-square bg-darker transition-colors hover:bg-dark rounded-full default-ring text-white flex items-center justify-center"
                (click)="onNextClick()">
                <ng-icon name="heroChevronRight" class="text-xl" />
              </button>
            </div>
            <div
              class="w-24 absolute right-0 -top-2 bg-gradient-to-l from-white via-white/50 to-transparent h-[14.5rem]"></div>
          </div>
          <!-- <div
            *ngIf="showPreviousClickButton()"
            class="absolute h-48 top-2 flex items-center justify-center w-10 -left-0 z-10">
            <div class="w-full bg-gradient-to-r from-transparent to-neutral-100"></div>
            <button
              class="w-full aspect-square bg-darker transition-colors hover:bg-dark rounded-full default-ring text-white flex items-center justify-center"
              (click)="onPreviousClick()">
              <ng-icon name="heroChevronLeft" class="text-xl" />
            </button>
          </div> -->
          <div *ngIf="showPreviousClickButton()" class="relative" [@fadeInOut]>
            <div class="absolute h-48 top-2 flex items-center justify-center w-10 left-0 z-10">
              <button
                class="w-full aspect-square bg-darker transition-colors hover:bg-dark rounded-full default-ring text-white flex items-center justify-center"
                (click)="onPreviousClick()">
                <ng-icon name="heroChevronLeft" class="text-xl" />
              </button>
            </div>
            <div
              class="w-24 absolute left-0 -top-2 bg-gradient-to-r from-white via-white/50 to-transparent h-[14.5rem]"></div>
          </div>
        </div>
        <div #container class="flex items-center justify-center w-full overflow-x-auto pb-4" (scroll)="onScroll()">
          @if (subtitles(); as _subtitles) { @for (subtitle of _subtitles; track $index) {
          <button
            class="flex flex-col items-center gap-1 p-4 rounded-lg transition-all"
            [ngClass]="{ 'bg-neutral-200': selectedCaptions() === subtitle.identifier }"
            (click)="selectedCaptions.set(subtitle.identifier)">
            <div class="h-48 aspect-mobile rounded-lg overflow-hidden bg-neutral-50 mb-2">
              <video
                [src]="subtitle.previewVideo"
                class="h-full"
                [playsInline]="'playsinline'"
                [muted]="'muted'"
                loop
                [autoplay]="'autoplay'"
                preload="metadata"></video>
            </div>
            <div class="text-sm text-dark text-center font-medium">{{ subtitle.name }}</div>
          </button>
          } }
          <!-- <button>
            <div class="h-48 aspect-mobile border rounded bg-neutral-50 mb-2"></div>
            <div class="text-sm text-dark text-center font-medium">Funky</div>
          </button>
          <button>
            <div class="h-48 aspect-mobile border rounded bg-neutral-50 mb-2"></div>
            <div class="text-sm text-dark text-center font-medium">Autocue</div>
          </button>
          <button>
            <div class="h-48 aspect-mobile border rounded bg-neutral-50 mb-2"></div>
            <div class="text-sm text-dark text-center font-medium">Classic</div>
          </button> -->
        </div>
      </div>
      } @case(1) {
      <div [@delayedAbsFadeInOutFromRight] class="mt-4">
        <app-ai-creation-video-options-layout (themesChanged)="themes.set($event)" />
      </div>
      } }
    </div>
    <div class="text-right shrink-0 relative h-6">
      <button
        *ngIf="currentStep < 1; else finishButton"
        class="btn button-primary text-sm flex items-center justify-center gap-2 absolute -right-2 -bottom-2 w-20"
        (click)="attemptNextStep()"
        [disabled]="!canProceedToNextStep()">
        Next
      </button>
      <ng-template #finishButton>
        <div class="absolute -right-2 -bottom-2">
          <button
            class="btn button-primary text-sm flex items-center justify-center gap-2 relative w-20"
            (click)="createProject()"
            [disabled]="!canProceedToNextStep() || loading">
            <span [ngClass]="{ hidden: !loading }" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <svg
                class="animate-spin h-4 w-4 text-dark opacity-50"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </span>
            <span [ngClass]="{ 'opacity-0': loading }"> Create </span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</ui-card>
