<div class="w-full lg:px-0 mx-auto mt-4">
  <div class="w-full rounded-lg flex flex-col xs:flex-row drop-shadow-lg items-center gap-4 xs:gap-0">
    <div class="border border-r-0 xs:rounded-r-none grow w-full h-full py-3.5 sm:py-4 rounded-lg flex bg-white">
      <div class="flex items-center">
        <ng-icon
          name="heroCloudArrowUp"
          class="text-neutral-500 text-xl h-6 w-6 ml-4 mr-1 cursor-pointer"
          (click)="openUploadDialog()" />
      </div>
      <input
        class="font-light px-2 sm:pl-2 pl-1 grow no-focus text-sm sm:text-base w-0 truncate"
        [placeholder]="placeholder()"
        [ngxTippy]="'We support YouTube videos, and Twitch clips/VODs. You can also upload your own video.'"
        [tippyProps]="{ delay: [300, 0] }"
        [formControl]="videoUrl" />
    </div>
    <button
      class="disabled:opacity-75 disabled:cursor-not-allowed bg-brand background-brand border border-brand xs:rounded-l-none rounded-lg border-l-0 px-4 sm:px-6 text-white h-full py-3.5 sm:py-4 block sm:text-lg w-fit z-10 flex items-center focus:outline-none shrink-0"
      [disabled]="videoUrl.invalid"
      (click)="openCreationOverlay()">
      <div class="flex items-center">
        <ng-icon name="heroSparklesSolid" class="mr-2 -ml-1 text-lg sm:text-xl h-0" />
      </div>
      <div class="font-bold text-sm sm:text-base">Create</div>
    </button>
  </div>
</div>
